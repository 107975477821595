import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// import components
import Layout from '../components/sections/layout'
import Seo from '../components/sections/seo'
import HeaderImage from '../components/headerImage'
import GoogleReviews from '../components/google_reviews/google_reviews'
import BlogTeaser from '../components/blog/blogTeaser'

// import templates
import BasicLayout from '../templates/basic-layout'

import styled from 'styled-components'
const BlogList = styled.ul`
  display: flex;
  flex-flow: wrap;
  flex-direction: column;

  margin-left: -15px;
  margin-right: -15px;

  margin-top: 80px;
  margin-bottom: 60px;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
`

const BlogItem = styled.li`
  flex-basis: 100%;
  margin: 10px;

  @media only screen and (min-width: 768px) {
    flex-basis: calc(50% - 20px);
  }
`

const seo_description = `Ty Paul Personal Training. Based in Hove & Brighton. Ty has over 16 years experience as a elite personal trainer and Gym manager, winner of 'Britain's Best Coach' by Men's Health Magazine and 5* Google rating.`
const seo_keywords = `Britain's Best Coach, Men's Health Magazine, 5* rating,  personal training Hove, personal training Brighton, online personal training, Hove personal trainer, older adults Hove, older adults Brighton, Best personal trainer Hove, Personal Trainer in Brighton, Personal Trainer in Hove, Personal Trainer Near Me, online personal trainer hove, online personal trainer brighton, PT Hove &Brighton 60s and over training`

const About = () => {
  const data = useStaticQuery(query)
  const { strapiAbout, allStrapiTeam } = data
  const { title, description, SEO } = strapiAbout

  const { page_description = seo_description, page_keywords = seo_keywords, page_title = title } = SEO

  return (
    <Layout>
      <Seo title={page_title} description={page_description} keywords={page_keywords} />
      <HeaderImage src={require(`../media/images/basic_layout_placeholder_image_1.png`).default} title={title} />
      <BasicLayout pageName={title} description={description} rightImage={''} images={[]} />
      <div className="container">
        <BlogList>
          {allStrapiTeam.nodes.length > 0 &&
            allStrapiTeam.nodes.map((node, index) => {
              return (
                <BlogItem key={index}>
                  <BlogTeaser data={node} slug={`/about/${node.slug}`} />
                </BlogItem>
              )
            })}
        </BlogList>
      </div>
      <GoogleReviews />
    </Layout>
  )
}

const query = graphql`
  {
    strapiAbout {
      SEO {
        page_description
        page_keywords
        page_title
      }
      title
      description
    }
    allStrapiTeam {
      nodes {
        title
        description
        slug
        main_image {
          url
        }
      }
    }
  }
`

export default About
